import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`5th October 2022`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now handle a GraphQL error using `}<a parentName="p" {...{
            "href": "type://GraphqlErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlErrorHandler.html"
          }}>{`type://GraphqlErrorHandler`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4435"
          }}>{`#4435`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GraphqlErrorHandler errorHandler = new GraphqlErrorHandler() {
  @Override
  public HttpResponse handle(ServiceRequestContext ctx, ExecutionInput input,
                             ExecutionResult result, @Nullable Throwable cause) {
    final List<GraphQLError> errors = result.getErrors();
    if (errors.stream().map(GraphQLError::getMessage)
              .anyMatch(m -> m.startsWith("Unauthorized"))) {
      return HttpResponse.of(HttpStatus.UNAUTHORIZED);
    }
    ...
  }
};
GraphqlService
  .builder()
  .schemaFile(...)
  .runtimeWiring(...)
  .errorHandler(errorHandler) // 👈👈👈
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily configure client-side TLS key and certificates to support mTLS via
`}<a parentName="p" {...{
            "href": "type://ClientFactoryBuilder#tls(File,File):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#tls(java.io.File,java.io.File)"
          }}>{`type://ClientFactoryBuilder#tls(File,File)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4410"
          }}>{`#4410`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`File keyCertChainFile = ...;
File keyFile = ...;
ClientFactory factory =
  ClientFactory
    .builder()
    .tls(keyCertChainFile, keyFile)
    .build();
WebClient
  .builder()
  .factory(factory)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`gRPC HTTP/JSON transcoding endpoint can now handle both the snake case and camel case query parameters
using `}<a parentName="p" {...{
            "href": "type://HttpJsonTranscodingOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/HttpJsonTranscodingOptions.html"
          }}>{`type://HttpJsonTranscodingOptions`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4428"
          }}>{`#4428`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpJsonTranscodingOptions options =
  HttpJsonTranscodingOptions
    .builder()
    // Try the original field name and camel case
    // if it's not found via the original name.
    .queryParamMatchRules(LOWER_CAMEL_CASE,
                          ORIGINAL_FIELD)
                          ...
                          .build();
GrpcService
  .builder()
  .enableHttpJsonTranscoding(options)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`OutputStream`}</inlineCode>{` to write data to a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://StreamMessage#fromOutputStream(Consumer):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#fromOutputStream(java.util.function.Consumer)"
          }}>{`type://StreamMessage#fromOutputStream(Consumer)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4402"
          }}>{`#4402`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage.fromOutputStream(os -> {
  // perform blocking operation
  HttpData data0 = readDataFromBlockingDB(0);
  os.write(data0);
  HttpData data1 = readDataFromBlockingDB(1);
  os.write(data1);
  os.close();
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now convert an `}<a parentName="p" {...{
            "href": "type://AggregatedHttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpRequest.html"
          }}>{`type://AggregatedHttpRequest`}</a>{` to an `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` with new headers. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4441"
          }}>{`#4441`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`AggregatedHttpRequest req = ...
HttpRequest httpRequest = req.toHttpRequest(newHeaders);
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`N/A`}</li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
        }}>{`type://GraphqlService`}</a>{` now respects the accept header correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4451"
        }}>{`#4451`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
        }}>{`type://THttpService`}</a>{` no longer throw double subscription exceptions when
the request is aggregated in a decorator. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4449"
        }}>{`#4449`}</a></li>
      <li parentName="ul">{`The content-length header is automatically removed from `}<a parentName="li" {...{
          "href": "type://ResponseHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseHeaders.html"
        }}>{`type://ResponseHeaders`}</a>{` for a server
streaming response in `}<a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4460"
        }}>{`#4460`}</a></li>
      <li parentName="ul">{`You no longer see a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` when using `}<a parentName="li" {...{
          "href": "type://EndpointSelectionStrategy#rampingUp():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html#rampingUp()"
        }}>{`type://EndpointSelectionStrategy#rampingUp()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4448"
        }}>{`#4448`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` no longer throw an exception when a protobuf message doesn't have any fields. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4436"
        }}>{`#4436`}</a></li>
      <li parentName="ul">{`You no longer see a 500 response when an empty multipart request is sent. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4432"
        }}>{`#4432`}</a></li>
      <li parentName="ul">{`The service name generated from a CGLIB-enhanced class no longer has a synthetic suffix in
`}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4430"
        }}>{`#4430`}</a></li>
      <li parentName="ul">{`Users can now use `}<a parentName="li" {...{
          "href": "type://ProtobufRequestConverterFunctionProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/protobuf/ProtobufRequestConverterFunctionProvider.html"
        }}>{`type://ProtobufRequestConverterFunctionProvider`}</a>{` without encountering a potential
`}<inlineCode parentName="li">{`ClassCastException`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4429"
        }}>{`#4429`}</a></li>
      <li parentName="ul">{`Fixed a regression where `}<inlineCode parentName="li">{`thrift0.9`}</inlineCode>{` is incompatible with Thrift 0.9.1 since Armeria 1.17.0. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4427"
        }}>{`#4427`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`enable-auto-injection`}</inlineCode>{` property is correctly added to the generated `}<inlineCode parentName="li">{`spring-configuration-metadata.json`}</inlineCode>{`
file. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4425"
        }}>{`#4425`}</a></li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`N/A`}</li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpRequest#aggregateWithPooledObjects(ByteBufAllocator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html#aggregateWithPooledObjects(io.netty.buffer.ByteBufAllocator)"
        }}>{`type://HttpRequest#aggregateWithPooledObjects(ByteBufAllocator)`}</a>{` and
`}<a parentName="li" {...{
          "href": "type://HttpResponse#aggregateWithPooledObjects(ByteBufAllocator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#aggregateWithPooledObjects(io.netty.buffer.ByteBufAllocator)"
        }}>{`type://HttpResponse#aggregateWithPooledObjects(ByteBufAllocator)`}</a>{` are deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4449"
        }}>{`#4449`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://HttpRequest#aggregate(AggregationOptions):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html#aggregate(com.linecorp.armeria.common.AggregationOptions)"
            }}>{`type://HttpRequest#aggregate(AggregationOptions)`}</a>{` with
`}<a parentName="li" {...{
              "href": "type://AggregationOptions#usePooledObjects(ByteBufAllocator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregationOptions.html#usePooledObjects(io.netty.buffer.ByteBufAllocator)"
            }}>{`type://AggregationOptions#usePooledObjects(ByteBufAllocator)`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#httpJsonTranscodingErrorHandler(UnframedGrpcErrorHandler):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#httpJsonTranscodingErrorHandler(com.linecorp.armeria.server.grpc.UnframedGrpcErrorHandler)"
        }}>{`type://GrpcServiceBuilder#httpJsonTranscodingErrorHandler(UnframedGrpcErrorHandler)`}</a>{` is deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4428"
        }}>{`#4428`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://HttpJsonTranscodingOptionsBuilder#errorHandler(UnframedGrpcErrorHandler):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/HttpJsonTranscodingOptionsBuilder.html#errorHandler(com.linecorp.armeria.server.grpc.UnframedGrpcErrorHandler)"
            }}>{`type://HttpJsonTranscodingOptionsBuilder#errorHandler(UnframedGrpcErrorHandler)`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`N/A`}</li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <p>{`You can use Thrift 0.17 with Armeria.`}</p>
    <ul>
      <li parentName="ul">{`Brave 5.13.11 → 5.14.0`}</li>
      <li parentName="ul">{`Dropwizard2 2.1.1 → 2.1.2`}</li>
      <li parentName="ul">{`GraphQL Java 19.0 → 19.2`}</li>
      <li parentName="ul">{`GraphQL Kotlin 6.2.2 → 6.2.5`}</li>
      <li parentName="ul">{`gRPC Java 1.49.0 → 1.49.1`}</li>
      <li parentName="ul">{`Kafka 3.2.1 → 3.3.0`}</li>
      <li parentName="ul">{`Kotlin 1.7.10 → 1.7.20`}</li>
      <li parentName="ul">{`Micrometer 1.9.3 → 1.9.4`}</li>
      <li parentName="ul">{`Netty 4.1.79.Final → 4.1.82.Final`}
        <ul parentName="li">
          <li parentName="ul">{`io_uring 0.0.14.Final → 0.0.15.Final`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reactor 3.4.22 → 3.4.23`}</li>
      <li parentName="ul">{`Sangria GraphQL 3.2.0 → 3.3.0`}</li>
      <li parentName="ul">{`Scala 2.12.16 → 2.12.17, 2.13.8 → 2.13.9`}</li>
      <li parentName="ul">{`Spring Boot 2.7.3 → 2.7.4`}</li>
      <li parentName="ul">{`Thrift 0.17.0 is added`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Bogyie', 'chungonn', 'heowc', 'ikhoon', 'injae-kim', 'jrhee17', 'ks-yim', 'minwoox', 'mscheong01', 'takezoe', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      